ul {
    display: flex;
    flex-direction: column;
    /* align-items:end; */
    justify-content: space;

}

.links li {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.right_links {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
}

.nav_item {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    /* color: black; */
}

.nav_item a:visited {
    color: black;
}

.nav_item .help_link {
    font-size: 13px;
    text-decoration: none;
}

.nav_item .help_link:hover {
    text-decoration: underline;
    transition: gray;
}

.profile:hover {
 text-decoration: underline;
}

.cart_button {
 font-family: sans-serif;
 font-size: 13px;
 text-decoration: none;
 display: flex;
 align-items: center;
}

.cart_button:hover {
 text-decoration: underline;
}

/* .profile-button {
    cursor: pointer;
} */

.phone_num {
    font-size: 12px;
    font-family: sans-serif;
}
/*
.phone_num:hover {
    text-decoration: underline;
} */

.categories {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home {
    margin: 20px;
}
