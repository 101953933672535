.shipping-outer-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: lightgray;
    overflow: hidden;
}

.shipping-inner-banner-info {
    width: 375px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.shipping-inner-banner-text {
    display: flex;
    flex-direction: row;

}

.modal-link {
    font-size: 15px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

