form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-radius: 1em;
    margin-right: 30%;
    margin-left: 30%;
}

.title {
    width: 150%;
    margin: 10%;
}

.login-modal-button {
    margin: 1%;
}

button:hover {
    transform: scale(1.01, 1.01);
}

.error_message {
    list-style: none;
    color: red;
    font-weight: bold;
}

.label {
    margin: 1%;
}

.input {
    border-radius: 1em;
    margin: 5%;
}

.login_modal {
    cursor: pointer
}

.sign-up-link {
    margin: 10%;
    width: 100%;
}
