.video-container {
    display: flex;
    justify-content: center;
    margin-left: 1.5%;
    margin-right: 1.5%;
    margin-bottom: 1%;
    overflow: hidden;
}

.video-container video {
    width: 100%;
}
