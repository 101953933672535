#shipping-modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #shipping-modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 80%;
  }

  #shipping-modal-content {
    height: 800px;
    width: 800px;
    position: absolute;
    background-color:white;
    border-radius: 0.3em;
  }

