.thankyou-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.social-media-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 30%;
}

.thankyou-form-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    font-size: 30px;
}

.social-media-links label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.logout-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30%;
}

.website-warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.website-warning a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
