#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 80%;
  }

  #modal-content {
    height: 600px;
    width: 550px;
    position: absolute;
    background-color:white;
    border-radius: 0.3em;
  }

  .login_modal {
    font-size: 13px;
  }

  .login_modal:hover {
    text-decoration: underline;
  }
