h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    text-decoration: underline;
    margin: 20px;
}
.gear-slot-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    margin: 20px;
}
.gear-slot-container img {
    width: 250px;
}
.category-link-img {
    background: rgb(208, 214, 216);
}

.category-link-img:hover {
    background: darkgray;
}
.balls-category-slot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bags-category-slot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gloves-category-slot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bags-category-slot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  