.product_search {
    display: flex;
    flex-direction: row;
}

.search_icon {
    cursor: pointer;
    background-color: transparent;
    border: none;
}
