form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-radius: 1em;
    margin-right: 30%;
    margin-left: 30%;
}

button:hover {
    transform: scale(1.01, 1.01);
}

.error_message {
    list-style: none;
    color: red;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.label {
    text-decoration: underline;
}

.input {
    border-radius: 1em;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: sans-serif;
    font-weight: bold;
}
.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
