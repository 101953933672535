.cart {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100vh;
}
.cart-right {
    display: flex;
    flex-direction: row;
}

.purchase-total-title {
    display: flex;
    flex-direction: column;
}
.cart-left {
    display: flex;
    flex-direction: column;
}

.cart-title {
    display: flex;
    justify-content: center;
    align-items: center;
}
