.product-ele{
    color: black;
    text-decoration: none;
}

.item-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}

.prod-container {
    display: flex;
    align-items: center;
    flex-direction:column;
}



.prod-image{
    background-color: rgb(208, 214, 216);
    margin: 10px
}
.prod-image:hover {
    background-color: darkgray;
}

.prod-image {
    width: 150px;
}
