.ssl-1 {
display: flex;
justify-content: center;
width: 100%;
height: 100%;
}

.react-multi-carousel-list {
width: 90%;
height: 100%;
}

.react-multiple-carousel__arrow:hover {
 margin-right: -5px;
 margin-left: -5px;
}
