.profile-button {
    border-style: none;
    background-color: transparent;
    cursor: pointer;
}

.profile-button:hover {
    text-decoration: underline;
}

.profile-dropdown {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.profile-dropdown li{
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 15px;
}

.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    display: inline-block;
}

.logout-button {
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
}

.user-profile-link-button {
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
}
