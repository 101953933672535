
.products_wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product_ele{
    color: black;
    text-decoration: none;
}

.product_container {
    display: flex;
    flex-direction: row;
}

.products-title {
    display: flex;
    justify-content: center;
    width: 100vw;
}

.products-list {
    display: flex;
    flex-wrap: wrap;
}

/* .product_li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
} */

