.search-bar {
    display: flex;
    flex-direction: row-reverse;
    align-self: flex-end;
    margin-right: -3%;
}

.category-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.category-ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.prod-ele {
    width: 50%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
}


.category {
    text-decoration: none;
    font-family: sans-serif;
    font-weight: bold;
}

.category-li a:any-link{
    color: black;
}

.categories-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%
}

.shipping-banner {
    margin-right: 0px;
}
