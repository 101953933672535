.outer-product-show-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.inner-product-show-container {
width: 100vw;
display: flex;
flex-direction: row;
justify-content: space-between;
}

.product-content-container {
    width: 300px;
    height: 500px;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
}

.prod-image-container {
    margin-top: 5%;
    margin-left: 10%;
    width: 600px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
}

.desc-container {
    display: flex;
    justify-content: center;

}

.price-container {
    display: flex;
    justify-content: center;
}

.review-smooth-scroll-label {
    cursor: pointer;
}

.prod-image {
width: 350px;
}

.add-button {
    width: 100px;
    height: 30px;
}


/* reviews css below in case I want ot split it up later*/

.outer-reviews-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.review-index {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;

}

.review-button {
    background-color: black;
    color: white;
    border-radius: 2em;
    border-style: solid;
    border-color: black;
    font-weight: bolder;
    font-size: 12px;
    font-family: sans-serif;
    width: 200px;
    height: 50px
}

/* .text {

} */

.read-hide-button {
    color: rgb(141, 141, 141);
}

.image-gallery-icon:hover {
    margin-top: -110px;
}

.spec-selection-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.spec-selection-wrapper .spec-selection {
    background-color: white;
    color: black;
}

.spec-selection-wrapper .spec-selection.selected{
    background-color: black;
    color: white;
}
