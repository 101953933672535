.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
}

.about-title {
    font-size: 30px;
}

.about-title-container {
    margin: 5%;
}

.personal-pitch {
    width: 25%;
    margin: 5%;
}

.about-info {
    display: flex;
    justify-content: center;
    align-items: center;

}

.return-link {

    margin: 5%;
}

.return-button {

}
