form {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    /* margin-left: 10%; */
    margin-top: 2%;
    align-items: center
}

label {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    margin-left: 10%;
    margin-right: 10%;
}

.review-container{
    height: 66vh;
    width: 50%;
}


textarea {
    margin-left: 60px;
    min-width: 200px;
    max-width: 250px;
}

input[type="number"] {
    max-width: 30px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .rating-input {
    display: flex;
    margin-right: 4px;
  }

  .rating-input .filled {
    color: rgb(120, 253, 19);
  }

  .rating-input .empty {
    color: rgb(25, 23, 23);
  }
