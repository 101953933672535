.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    background-color: bisque;
    width: 100%;
}

.legal-links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.corporate-links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.help-links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.personal-image img {
    width: 100px;
    border-radius: 3em;
}
