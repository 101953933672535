.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    /* height: 500vh; */
    overflow: hidden;

}
.video-wrapper {
    display: flex;
    justify-content: center;
    max-width: 100%;
    height: 900px;
}

.slot-swiper-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.slot-swiper-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

}
.slot-swiper-3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.top-banner {
    margin: 50px;
    width: 100%;
    height: 500px;
}
.category-slot-1 {
   width: 100vw;
   height: 35vh;
}
.category-slot-2 {
   width: 100vw;
   height: 80vh;
}

.mid-banner {
    margin: 50px;
    width: 100%;
    height: 500px;
}
.bot-banner {
    margin: 50px;
    width: 100%;
    height: 400px;
}
